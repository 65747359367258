import React from 'react'

const SvgCanon = props => (
  <svg width={125} height={63}>
    <title>{'Cannon'}</title>
    <g fillRule="nonzero" fill="#FFF">
      <path d="M64.486.933C53.154.933 43.341 7 38.084 16.1H26.87v9.333H124.182V16.1H91.005C85.63 7 75.818.933 64.486.933zM75.467 8.4l-7.593 7.583h-.117V6.067c2.687.35 5.374 1.166 7.71 2.333zM62.5 16.1l-8.294-8.167A27.842 27.842 0 0 1 62.5 5.95V16.1zm-13.084-5.6l5.49 5.483H43.693c1.635-2.1 3.621-3.966 5.724-5.483zm25.818 5.6l4.79-4.783c1.752 1.4 3.387 3.033 4.672 4.783h-9.462zM95.21 31.5v-1.75H33.995v1.75c0 4.083.818 7.933 2.22 11.55l-6.425 3.733s-1.636 1.284-3.271 1.984c-2.57 1.166-2.92 1.05-2.92 1.05H.233v8.866h23.364l2.337-.233c1.051-.117 1.752-.467 2.686-.933 1.636-.817 5.608-3.034 5.608-3.15l6.542-3.734c5.608 7 14.252 11.434 23.949 11.434 16.705 0 30.49-13.65 30.49-30.567zM62.5 40.95v16.1a27.842 27.842 0 0 1-8.294-1.983L62.5 40.95zm5.257-.117l8.06 13.65c-2.453 1.284-5.14 2.1-8.06 2.45v-16.1zm12.5 10.734L72.547 38.5l12.85 7.583a22.976 22.976 0 0 1-5.14 5.484zM76.869 35h12.85a31.762 31.762 0 0 1-1.752 6.533L76.87 35zm-38.084 0h11.098l-9.696 5.6c-.584-1.75-1.168-3.617-1.402-5.6zm5.958 13.3l11.215-6.533-6.425 10.85c-1.753-1.284-3.388-2.8-4.79-4.317z" />
      <ellipse cx={22.196} cy={20.767} rx={1.869} ry={1.867} />
    </g>
  </svg>
)

export default SvgCanon
